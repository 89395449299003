// SHOPIFY - Button Integration
import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';

const BuyButton = styled.div`
  &.sold-out,
  &.sold-out:hover {
    pointer-events: none;
    background: #DFDFDF !important;
    color: #000 !important;
    text-shadow: none;
  }
`;



class ShopifyBuyButton extends React.Component {
  static contextType = LuxWeb3Context;
  constructor( props ) {
    super( props );

    // SET INITIAL BUTTON TEXT
    const {product, selectedVariantId} = props;
    const buttonData = this.getButtonData(product,selectedVariantId);

    // STATE SETUP
    this.state = {
      buttonText: buttonData.buttonText,
      enableButton: buttonData.enableButton
    }
    this.addToCart = this.addToCart.bind(this);
    this.resetButtonState = this.resetButtonState.bind(this);
  }


  // ENABLE BUTTON ONCE VARIANT SELECTED
  componentDidUpdate(prevProps) {
    const {product, selectedVariantId } = this.props;

    if (product?.variants?.length > 1 
        && selectedVariantId !== null 
        && selectedVariantId !== prevProps.selectedVariantId 
        && product.tags.indexOf('coming-soon') === -1) {
      this.setState({
        buttonText: 'Add to Cart',
        enableButton: true
      });
    }
  }

  getButtonData(product, selectedVariantId) {
    let buttonText = '';
    let enableButton = false;

    if (!!product) {
      const available = product.is_available;
      buttonText = available ? `Add to Cart` : 'Sold Out';
      enableButton = available;

      // DISABLE - Need to pick option first
      if (product.variants.length > 1 && selectedVariantId === null) {
        buttonText = 'Pick a Size'
        enableButton = false
      }
    }

    // DISABLE - Coming Soon Item
    if (product.tags.indexOf('coming-soon') > -1) {
      buttonText = 'Coming Soon'
      enableButton = false
    }

    return {
      buttonText,
      enableButton
    }
  }

  resetButtonState(delayMs = 1000) {
    setTimeout( function() {
      const {product, selectedVariantId} = this.props;
      const data = this.getButtonData(product, selectedVariantId);

      this.setState({
        buttonText: data.buttonText,
        enableButton: data.enableButton
      })
    }.bind(this), delayMs)
  }


  // CART : Add item selection to cart 
  addToCart() {
    const { product, selectedVariantId } = this.props
    const cartItem = Object.assign({}, product)
    cartItem.variant_id = selectedVariantId; // Overwrite to their selected option
    const result = this.context.updateCart(cartItem, 'add')
    
    // RENDER RESULT + START TIMER TO REACTIVATE
    this.setState({
      buttonText: result,
      enableButton: false,
    })
    this.resetButtonState(1800);
  }

  render() {
    const { 
      buttonText, 
      enableButton,
    } = this.state

    return (
      <>
        <BuyButton
          className={`action-button cult-button ${enableButton ? '' : 'sold-out'}`} onClick={this.addToCart}>{buttonText}</BuyButton>
      </>
    )
  }
}

export default ShopifyBuyButton
