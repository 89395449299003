import React from "react";
import { debounce } from "lodash";
import styled from "styled-components";
import { Section } from "../../../components/Core";

import EyeTunnel from '../../../components/TheEye/EyeTunnel';
// import ConnectButton from "../../../components/Web3Utils/ConnectButton";
import HeaderPanel from "../../../components/LuxContent/HeaderPanel";
import ContentPanel from "../../../components/LuxContent/ContentPanel";
import LuxWeb3Context from '../../../context/LuxWeb3Context';

const EntryWrap = styled(Section)`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 0;

  svg {
    transform: translate(-50%, -50%);
  }

  &.enlightenment-mode {
    .outer-wrap {
      opacity: 0;
      pointer-events: none;
      transition: all 0.25s ease-in-out;
    }
  }
`;



class EntryEye extends React.Component {
  static contextType = LuxWeb3Context;
  constructor(props) {
    super(props);
    this.state = {
      height: '100vh',
      width: 'auto'
    }

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', debounce(this.handleResize, 300) )
    this.handleResize();
    this.context.getShopifyProducts();
  }


  // UTIL : Set New Screen-proper Dimensions on EyeTunnel
  setDimensions(dimensions) {
    this.setState(dimensions);
  }

  // UTIL : Resize window to properly fit the eye tunnel animation
  handleResize() {
    const w = window.innerWidth;
    const h = window.innerHeight;
    const ratio = w/h;
    // console.log('RESIZE EVENT -- Size: ', window.innerWidth, 'x', window.innerHeight, ' RATIO: ', ratio);

    if ( ratio > 1.7777777 ) {
      this.setDimensions({
        height: 'auto',
        width: '100vw'
      })
    }
    else {
      this.setDimensions({
        height: '100vh',
        width: 'auto'
      })
    }
  }

  render() {
    const { enlightenmentMode, isMenuOpen, location } = this.context;
    const enlightenmentClass = enlightenmentMode ? 'enlightenment-mode' : '';
    const dimensions = this.state;
    const isSSR = typeof window === 'undefined';

    return(
      <>
        { !isSSR && 
          <EntryWrap id="entry-eye" className={ !!location ? `page-${location} ${enlightenmentClass}` : `page-home ${enlightenmentClass}`}>

            {/* BACKGROUND */}
            <EyeTunnel dimensions={dimensions} />


            {/* CONTENT */}
            <div className="content-wrap">

              {/* HEADER */}
              <HeaderPanel />
              
              <div className="outer-wrap">
                {/* Passedas props on purpose so we can limit unnecessary 
                    renders from context always triggering in lower levels */}
                <ContentPanel isMenuOpen={isMenuOpen} />
              </div>
            </div>

          </EntryWrap>
        } 
      </>
    )
  };
}

export default EntryEye;
