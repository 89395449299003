import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';
import CartItem from './CartItem';

// UTILS
import { device } from "../../utils";
import { randomNum } from "../../utils/helperFn";


// ~~~~ STYLING
const CartListWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 88px 0px 0px;
  max-width: 800px;
  max-height: calc(100vh - 150px);

  .cart-empty {
    margin-bottom: 14px;
  }

  .cart-list {
    height: 100%;
    overflow: scroll;
    padding: 0 10px 60px;
    width: 100%;
  }
`;

const CheckoutRow = styled.div`
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    text-align: right;
    padding: 15px 16px;
    display: flex;
    align-items: center;
    border: none;
    border-bottom: 1px dashed #000;
    box-shadow: none;

      .cart-total {
        width: 100%;
        text-align: right;
        padding: 7px 20px 7px 0px;
        font-size: 24px;
        line-height: 24px;

        @media ${device.md} {
            font-size: 24px;
        }
      }

    a {
        width: 100%;
        text-align: center;
        font-size: 18px;

        @media ${device.md} {
          font-size: 28px;
        }
    }
`;


class CartList extends React.Component {
  static contextType = LuxWeb3Context;

  getCartTotal( cartProducts ) {
    let total = 0;
    cartProducts.forEach( product => {
        if (product.variants?.length <= 1) {
            total = total + JSON.parse(product.price)
        }

        else {
            const productVariant = product.variants.find( function(variant) {
                return JSON.parse(product.variant_id) === JSON.parse(variant.id) 
            });
            total = total + JSON.parse(productVariant.price)
        }
    })
    return total;
  }

  render() {
    const { cartProducts } = this.context;
    const cartTotal = this.getCartTotal(cartProducts);


    // EMPTY CART
    let checkoutRow = null;
    let cartContent = (
        <div className="cart-empty">
            <br/>Cart is empty
            <br/><br/><div className="cult-button" onClick={this.context.setLocationShop}>Visit Shop</div>
        </div>
    )

    // CART HAS ITEMS
    if (cartProducts.length > 0 ) {
        const variantIds = cartProducts.map( item => `${item.variant_id}:1`) // The :1 is quantity
        checkoutRow = (
            <CheckoutRow className="cult-panel">
                <div className="cart-total">TOTAL  ${cartTotal} </div>
                <a className={`action-button cult-button`} href={`https://cultofneon.myshopify.com/cart/${variantIds}?channel=buy_button`}>
                    Check Out
                </a>
            </CheckoutRow>
        )

        // CART ITEMS ITERATOR
        cartContent = cartProducts.map(product => (
            <CartItem key={`${product.variant_id}-${randomNum(9999)}`} product={product} />
        ))
    }

// ~~~~ COMPONENT BODY
    return (
      <CartListWrap className="cult-panel">
        {checkoutRow}
        <div className="cart-list">
          {cartContent} 
        </div>
      </CartListWrap>
    )
  }
};

export default CartList;
