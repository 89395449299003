import React from 'react'
import styled from "styled-components";
import { marked } from 'marked';
import { device } from "../../utils";
import ItemProperties from './ItemProperties';
import LuxWeb3Context from '../../context/LuxWeb3Context';


// ~~~~ STYLING
const ItemDescriptionWrap = styled.div`
  position: relative;
  padding: 15px 10px 75px;
  text-align: left;

  @media ${device.md} {
    padding-bottom: 105px;
  }

  @media ${device.xl} {
    padding-bottom: 75px;
  }

  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .product-title {
    // Not used really title looks fine, but there for desire..
  }

  .product-lore,
  .product-description {
    font-size: 16px;
  }

  .prop-item {
    font-size: 18px;
  }

  hr {
    border-top: 1px solid #000;
  }
`;



class ItemDescription extends React.Component {
  static contextType = LuxWeb3Context;


  render() {
    const { description, productTags, title } = this.props;
    const mergedDescription = `${description}`;
    const cleanDescription = mergedDescription.replace(/\[http:\/\/cultofneon.art\]/gi, 'https://cultofneon.art');

// ~~~~ COMPONENT BODY
    return (
      <ItemDescriptionWrap className="cult-panel-colors">
        <ItemProperties productTags={productTags} />
        <br/>
        <div dangerouslySetInnerHTML={{__html: marked.parse( cleanDescription ) }}></div>
      </ItemDescriptionWrap>
    )
  }
};

export default ItemDescription;