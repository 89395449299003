import React from 'react'
import styled from "styled-components";
import ShopGalleryItem from './ShopGalleryItem';
import ShopFilters from './ShopFilters';

// UTILS
import { device } from "../../utils";


// ~~~~ STYLING
const ShopGalleryWrap = styled.div`
  position: relative;
  width: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${device.xl} {
    justify-content: space-around;
  }
`;


class ShopGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shopFilter: null
    }

    this.setShopFilter = this.setShopFilter.bind(this);
  }

  // PREVENTS SHOP RERENDERING EVERY TIME THE NAV MENU IS OPEN 
  // WHY? Context is causing everything to update, so we're preventing that for the most part here
  shouldComponentUpdate(prevProps) {
    if (prevProps.isMenuOpen !== this.props.isMenuOpen) {
      return false;
    }
    return true;
  }



  setShopFilter(e) {
    let filter = e.target.id.split('filter-')[1];

    // UNDOING? If clicking same filter it will unapply it
    if ( e.target.classList.contains('active') ) {
        filter = null
    }
    this.setState({ shopFilter: filter });
  }



  render() {
    const { shopFilter } = this.state;
    const { shopifyProducts } = this.props;
    let filteredProducts = shopifyProducts;


    // FILTER THE LIST OF PRODUCTS FROM QUICK FILTER CHOICES
    if ( !!shopifyProducts && shopifyProducts.length > 0 ) {
      // APPAREL FILTER
      if (!!shopFilter && shopFilter === 'apparel' ) {
        filteredProducts = filteredProducts.filter(item => item.product_type === "Gear")
      }

      // NEON FILTER
      if (!!shopFilter && shopFilter === 'neon' ) {
        filteredProducts = filteredProducts.filter(item => item.product_type !== "Gear")
      }
    }


// ~~~~ COMPONENT BODY
    return (
      <ShopGalleryWrap>
        <ShopFilters setShopFilter={this.setShopFilter} shopFilter={shopFilter} />

        {( filteredProducts.length > 0 ) && 
          filteredProducts.map(product => (
            <ShopGalleryItem key={product.id} product={product} />
          ))
        }
      </ShopGalleryWrap>
    )
  }
};

export default ShopGallery;
