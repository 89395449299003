import React from "react";
import { device, theme } from "../../../utils";
import styled from "styled-components";
import LuxWeb3Context from "../../../context/LuxWeb3Context";
import lux_cloud from '../../../assets/image/cult/lux_cloud.jpg';
import claim_sample from '../../../assets/image/cult/neon_claim_demo.png';
import ContentAbout from "./about";
import PrivacyPolicy from "./privacyPolicy";
import TermsOfService from "./termsOfService";
import Returns from "./returns";
import Contact from "./contact";

const ContentInfoWrap = styled.div`
  position: relative;

  #info-content {
    padding-top: 10px;

    @media ${device.md} {
      padding-top: 20px;
    }



    // HEADER
    .content-header {
      margin-bottom: 10px;
      font-size: 20px;
      text-shadow: 0 0 0 #000;
      cursor: pointer;
      text-align: center;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      max-width: 400px;
      width: 95%;

      @media ${device.sm} {
        font-size: 24px;
      }

      @media ${device.md} {
        font-size: 27px;
        margin-bottom: 10px;
      }

      &.cult-button {
        margin-bottom: 15px;
      }
    }

    .text-subheader {
      font-size: 18px;
      // text-shadow: 0 0 1px #000;
      margin: 10px 0;
      line-height: 18px;

      @media ${device.md} {
        font-size: 27px;
        line-height: 22px;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }


    // BODY CONTENT
    .body-wrap {
      height: 0;
      overflow: hidden;

      &.open {
        height: 100%;
        overflow: visible;
      }
    }

    .content-body {
      font-size: 16px;
      padding: 0 5px;
      text-align: center;
      
      @media ${device.lg} {
        font-size: 20px;
        padding: 0 90px;
      }

      &.content-entry {
        @media ${device.md} {
          font-size: 22px;
        }
      }

      .content-image {
        width: 100%;
      }

      &.image-wrap img {
        max-width: 850px;
      }

      .exchange-image-wrap {
        position: relative;
        margin-bottom: 40px !important;

        .text-subheader.nft-text,
        .text-subheader.claimed-neon-text {
          position: absolute;
          bottom: -40px;
        }
        .text-subheader.nft-text {
          left: 10px;

          @media ${device.md} {
            left: 87px;
          }
        }
        .text-subheader.claimed-neon-text {
          right: 10px;
          
          @media ${device.md} {
            right: 87px;
          }
        }
      }

      // CULT SUBHEADERS (BUTTON STYLE ONES)
      .cult-button {
        font-size: 18px;

        @media ${device.md} {
          font-size: 22px;
        }
      }

      .cult-button-subheader {
        padding-top: 2px;
        padding-bottom: 2px;
        margin-bottom: 12px;
        display: inline-flex;
        // color: #000 !important;
        // background: #eee !important;
      }
  

      .text-bold {
        text-shadow: 0px 0px 1px #000;
        color: ${theme.cultPalette[1] === '#950AFF' ? '#cecece':'inherit'};
      }
    }
  
    // UTIL
    .text-center {
      text-align: center;
    }
  }
`;

class ContentInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTab: null
    }
    this.open = this.open.bind(this);
  }
  static contextType = LuxWeb3Context;

  componentDidUpdate(prevProps, prevState) {
    const { openTab } = this.state;
    const prevTab = prevState.openTab;

    if ( !!openTab && openTab !== prevTab ) {
      this.resetContentScroll( openTab );
    }
  }

  open(e) {
    const id = e.target.id;
    const selection = id && this.state.openTab !== id ? id : null;
    this.setState({openTab: selection});
  }

  resetContentScroll(selection) {
    const scrollBox = document.getElementById('content-scroll-box');
    const scrollTarget = document.getElementById( selection );
    if ( !!scrollBox && !!scrollBox.scrollTo && !!scrollTarget ) {
      scrollBox.scrollTo(0, 0);
      const targetLocation = scrollTarget.getBoundingClientRect();
      const parentLocation = scrollBox.getBoundingClientRect();
      const distanceToScroll = targetLocation.top - parentLocation.top - 20;
      // console.log( `selection top = ${targetLocation.top} \ntarget top    = ${parentLocation.height} \nscroll amount = ${distanceToScroll}` );
      scrollBox.scrollTo(0, distanceToScroll);
    } 
  }

  render() {
    const { openTab } = this.state;
    const { setLocationShop } = this.context;

    return (
      <ContentInfoWrap>
          
        <ul id="info-content" ref="">
          <ContentAbout noNftsFound={this.props.noNftsFound}/>
          <br/>

          {/* NEON SPIRITS */}
          <li className="content-header cult-button" id="overview" onClick={this.open}>-\\ NEON SPIRITS //-</li>
          <div className={openTab === 'overview' ? 'body-wrap open': 'body-wrap'}>
            <li className="content-body">
              Beings from a light-based dimension hidden within our own, they manifest unto our world through strong emotional events, possessing an array of powers over the physical plane. 
              <br/><br/><br/><br/>
              <div className="cult-button cult-button-subheader cult-button-alt-colors">Afflicted Objects</div>
              <br/>Born when household items are inhabited by Neon spirits, possession has been observed to cause unpredictable mutations within host items.
              <br/><br/>Discernable by their glowing forms, Afflicted Objects can exert physical + psychological effects when in their vicinity - euphoria, entrancement and teleportation have all been recorded.
              {/* <br/><br/>Incidents throughout history have been attributed to powerful Afflicted Objects, and Improper handling when containing one can result in severe injury. */}
              <br/><br/><i>Follow containment protocol at all times.</i>
              <br/><br/><br/><br/>
              <div className="cult-button cult-button-subheader cult-button-alt-colors">Containment Protocols</div>
              <br/><div className="text-subheader cult-link">Tier I - Luminous Class</div>
              <i>Gentle or otherwise good-natured spirits. Typically exhibit powers of fortune, infatuation or illusion. <br/>A few assist in cult of neon operations.</i>
              <br/><br/><div className="text-bold">Containment</div>Lumen-grade Seal or higher
              <br/><br/><br/><br/>
              <div className="text-subheader cult-link">Tier II - Hex Class</div>
              <i>Dangerous - able to attack mentally or physically.<br/>Rare cases known to consume humans.<br/>Exercise extreme caution.</i>
              <br/><br/><div className="text-bold">Containment</div>Radiant-grade Seal or higher<br/>Matched elemental containment vessel
              <br/><br/><br/><br/>
              <div className="text-subheader cult-link">Tier III - Celestial Class</div>
              <i>Catastrophe-level spirits capable of city-wide area manipulations.<br/>Last known encounter : [redacted]</i>
              <br/><br/><div className="text-bold">Containment</div>[redacted]
              <br/><br/>
            </li>
          </div>



          {/* THE SCHEMATIC */}
          <li className="content-header cult-button" id="shipping" onClick={this.open}>.|| THE ARTWORK ||.</li>
          <div className={openTab === 'shipping' ? 'body-wrap open': 'body-wrap'}>
            <li className="content-body">
              <br/><div className="cult-button cult-button-subheader cult-button-alt-colors">The Neon</div>
              <br/>Each glass neon is an Exclusive, 1 of 1 edition artwork, and includes lore, mounting hardware, US wall plug, bender's schematic, care guide, and other surprises ^_^
              <br/><br/>Modern neon is quiet, cool to the touch, and lasts many years.
              <br/><br/><br/><br/>
              <div className="cult-button cult-button-subheader cult-button-alt-colors">The Schematic</div>
              <br/>The web3 digital schematic included with each neon is the bending pattern used to craft the neon, and serves as your certificate of authenticity.
              <br/><br/>
              {/* SCHEMATIC SAMPLE */}
              <div className="content-body image-wrap exchange-image-wrap">
                <img className="content-image" alt="Sample of Schematic used for Neon Piece" src={claim_sample}  />
                <div className="text-subheader nft-text">Schematic</div>
                <div className="text-subheader claimed-neon-text">Physical Neon</div>
              </div>
              <br/>
            </li>
            <li className="content-body">
              Included with each neon is an info card with your piece's lore, care and setup guide.
              <br/><br/>
              Once shipped, we'll also send you the web3 <a className="cult-link" href="https://metamask.io/" target="_blank">Metamask wallet</a> holding your neon's schematic on the blockchain, which serves as your digital certificate of authenticity.
              { /*
                <br/><br/>Connect the wallet holding your schematic on this site to unlock the <i>"members area"</i> and <i>"request a repair"</i> menu options.
              */}
            </li>
            <br/><br/>
            <Contact />
            <br/>
          </div>



          {/* HOW DOES IT WORK */}
          <li className="content-header cult-button" id="howitworks" onClick={this.open}>~// Install Guide \\~</li>
          <div className={openTab === 'howitworks' ? 'body-wrap open': 'body-wrap'}>
            <br/>

            {/*  METAMASK PART
            <li className="content-body text-center"><div className="text-subheader">STEP 1 - WALLET</div></li>
            <li className="content-body">
              Setup a <a className="cult-link" href="https://metamask.io/" target="_blank">Metamask wallet</a> + load it with some Ethereum
            </li>
            <br/>
            */}

            {/* PURCHASE */}
            <li className="content-body text-center"><i>Each neon includes an info card with matching video install guide.</i></li>
            <br/>
            <li className="content-body text-center"><i>For example - If your neon is wall mounted, the video would walk you through these steps below.</i></li>
            <br/>
            {/* <li className="text-subheader text-center">All Artworks include..</li>
            <li className="content-body">
              - Install Guide Sheet
              <br/>- Clear Mounting Hardware
              <br/>- Digital Schematic / Authenticity Card
              <br/><br/>
            </li> 
            <br/> */}
            <li className="content-body text-center">
              <div className="text-subheader">STEP 1 - Prep</div>
              Find the included mount guide paper & tape it to a wall where you want your neon.
              <br/><br/>
              <div className="text-subheader">STEP 2 - Mark Screw Holes</div>
              {/*  */}
              Using a pen or pencil, draw in each designated screw hole to mark the points on the wall. 
              <br/><br/>
              <div className="text-subheader">STEP 3 - Loosely Attach Mounts</div>
              Remove mounting guide from wall and Screw included mount clips to each marked spot, leaving the screws juuust tight enough to rotate around the screw as needed in step 4.
              <br/><br/>
              <div className="text-subheader">STEP 4 - Align Clips</div>
              Gently take the neon and align the mount clips so they clip onto neon. Carefully trace the edge of each clip, then put the neon back down and give the clips a final tighten to their marked positions.
              <br/><br/>
              <div className="text-subheader">STEP 5 - Attach Neon</div>
              Gently press the neon into each clip. It should pop in gently. Once secured, plug in your new sign.
              <br/><br/>
              <div className="text-subheader">~// Bask in neon hues \\~</div>
            </li>
            {/*  OLD CONTENT
              <br/><br/>
              <li className="content-body text-center">
                <div className="text-subheader">STEP 4 - CLAIM</div>
                Select <i className="text-bold">"Claim Neon"</i> on the schematic & provide shipping info. <br/><br/>Each piece is 1 of 1, and the schematic is proof of ownership.
                <br/><br/>
                *Only <a className="cult-link" href="https://metamask.io/" target="_blank">Metamask wallet</a> supported for now.
                <div className="content-body image-wrap exchange-image-wrap">
                  <img className="content-image" alt="Sample of Schematic exchanged for Physical Neon" src={claim_sample}  />
                  <div className="text-subheader nft-text">Schematic</div>
                  <div className="text-subheader claimed-neon-text">Physical Neon</div>
                </div>
              </li>
              <br/>
              <li className="content-body text-center">
                <div className="text-subheader">STEP 5 - SHIP</div>
                We bend the neon based on the Digital Schematic, then ship it with tracking number & care guide.
              </li> 
            */}
            <br/>
            <br/>
          </div>
 


          {/* ARTIST */}
          <li className="content-header cult-button" id="artist" onClick={this.open}>+// ARTIST \\+</li>
          <div className={openTab === 'artist' ? 'body-wrap open': 'body-wrap'}>
            <li className="content-body">
              {/* <br/><div className="text-subheader">Creator</div> */}
              <br/><div className="cult-button cult-button-subheader cult-button-alt-colors text-center">Creator</div>
              <br/><a className="cult-link" href="http://lux-capacitor.com" target="_blank" rel="noreferrer">Lux_Capacitor</a> is equal parts dev + artist.
              <br/><br/>Conjuring designs drafted in daydreams, <b className="cult-link">Cult of Neon</b> is the embodiment of his surrealist fascination with light as a medium, collaborating with local artisans to bring luminous visions to life.
              <br/><br/><a className="cult-link" href="http://lux-capacitor.com" target="_blank" rel="noreferrer">lux-capacitor.com</a>
              <br/><br/><br/><br/>
              {/* <div className="text-subheader">Benders</div> */}
              <div className="cult-button cult-button-subheader cult-button-alt-colors text-center">Benders</div>
              <br/>Our neon is locally made in LA at <a className="cult-link" href="https://lasignstech.com/">LA Signs Tech</a>, summoning forth art into reality. They're a family-run business with decades of skill.
              <br/><br/><a className="cult-link" href="https://lasignstech.com/">LASignsTech.com</a>
            </li>
            <br/>
            <div className="content-body image-wrap">
              <img className="content-image" src={lux_cloud} alt="4ft wide, pastel hue, melting cloud neon artwork by Lux_Capacitor" />
              <i>"Prismatic.Sunset"</i> - One of our many collaborations
            </div>
            <br />
          </div>


          {/* ABOUT NEON */}
          <li className="content-header cult-button" id="aboutneon" onClick={this.open}>./? ABOUT NEON ?\.</li>
          <div className={openTab === 'aboutneon' ? 'body-wrap open': 'body-wrap'}>
            <li className="content-body text-center">Modern neon is quiet, cool to the touch and lasts for many years.</li>
            <br/><br/>
            {/* <div className="text-subheader text-center">What is Neon?</div> */}
            <li className="content-body">
              <div className="cult-button cult-button-subheader cult-button-alt-colors text-center">What is Neon?</div>
              <br/>Traditional "Neon" itself is a red-colored, noble gas element. When electrified in a glass tube under pressure, it emits a warm, red glow.
              <br/><br/>
              It may surprise you that many neon pieces are typically filled with an Argon-Helium gas blend that glows blue-white when electrified.
            </li>
            <br/><br/>
            {/* <div className="text-subheader text-center">Colors</div> */}
            <li className="content-body">
              <div className="cult-button cult-button-subheader cult-button-alt-colors text-center">Colors</div>
              <br/>To create a radiant hue, most glass is coated inside with colored phosphorous that, when electified, illuminates a bright color based on the composition. Today, a wide variety of colors can be attained to get the desired appearance desired for a design.
              <br/><br/>Glass itself can also be colored, and often creates a very surrealistic look when combined with the red of neon gas.
              <br/><br/>EX: Purple glass with red neon gas makes an ethereal tone.
            </li>
            <br/><br/>
            {/* <div className="text-subheader text-center">Bending</div> */}
            <li className="content-body">
              <div className="cult-button cult-button-subheader cult-button-alt-colors text-center">Bending</div>
              <br/>Glass itself - normally rigid - can be surprisingly flexible when subjected to focused heating via specialized flame torches.
              <br/><br/>
              A bender carefully heats the glass tube, balancing temperature using air and motion to bend it into shape. Mastering the craft can take years of practice, and is an incredible skill to observe in action or try out yourself.
            </li>
            <br/><br/>
            <div className="text-subheader text-center">Learn to Bend</div>
            <li className="content-body">
              In Los Angeles, the <a className="cult-link" href="https://neonmona.org" target="_blank"> Museum of Neon Art</a> conducts regular classes in which you can learn to bend your very own neon! Its very fun, we highly suggest checking them out!
              <br/><br/>
              <a className="cult-button" href="https://store.neonmona.org/collections/classes" target="_blank">See Classes</a>
            </li>
            <br/>
            <br/>
          </div>

          {/* MORE INFO */}
          {/*}
          <li className="content-header cult-button" id="utility" onClick={this.open}>=// UTILITY \\=</li>
          <div className={openTab === 'utility' ? 'body-wrap open': 'body-wrap'}>
            <li className="content-body text-center">1 Schematic = 1 Neon Artwork</li>
            <br/>
            <li className="content-body">
              Each Cult of Neon Schematic owned also grants 1 allowlist spot on the future series:
              <br/><i className="text-bold">Cult of Neon - Sacred Artifacts</i>
              <br/><br/>Holders gain early previews & release dates on future 1/1 works
              <br/><br/>More to come..
              <br/><br/>
            </li>
          </div>
          */}

          {/* DIVIDER */}
          <li className="content-header">--------</li>


          {/* PRIVACY + DATA */}
          <li className="content-header cult-button" id="privacy" onClick={this.open}>.|' PRIVACY POLICY '|.</li>
          <div className={openTab === 'privacy' ? 'body-wrap open': 'body-wrap'}>
            <PrivacyPolicy />
          </div>


          {/* TERMS OF SERVICE */}
          <li className="content-header cult-button" id="terms" onClick={this.open}>!|' TERMS OF SERVICE '|!</li>
          <div className={openTab === 'terms' ? 'body-wrap open': 'body-wrap'}>
            <TermsOfService />
          </div>


          {/* RETURNS */}
          <li className="content-header cult-button" id="returns" onClick={this.open}>:'| RETURNS |':</li>
          <div className={openTab === 'returns' ? 'body-wrap open': 'body-wrap'}>
            <Returns />
          </div>
          
        </ul>
      </ContentInfoWrap>
    );
  }
};

export default ContentInfo;