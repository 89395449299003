


import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';
import { device } from "../../utils";

// ~~~~ STYLING
const FiltersWrap = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;

    .spacer { // Ensures it takes a full row in the shop list
        width: 100%;
    }


    .filter-panel { 
        display: flex;
        padding: 8px 10px 12px;
        align-items: center;
    }


    .filter-header {
        text-align: center;
        min-width: 90px;

        @media ${device.sm} {
            min-width: 130px;
        }
    }
    
    .quick-filter-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        padding-bottom: 0;

        .quick-filter {
            font-size: 14px;

            &#filter-apparel {
                margin-right: 10px;
            }
        }
    }
`;



class ShopFilters extends React.Component {
    static contextType = LuxWeb3Context;
    constructor(props) {
        super(props);
    }

    render() {
        const { shopFilter, setShopFilter } = this.props;


        // ~~~~ COMPONENT BODY - Need extra few layers since its inside the list of the products so it can stay 100% wide
        return (
        <FiltersWrap>
            <div className="spacer"></div>
            <div className="cult-panel filter-panel">
                <div className="filter-header">FILTER</div>
                <ul className="quick-filter-wrap">
                    <li id="filter-apparel" className={`quick-filter cult-button ${shopFilter === 'apparel' ? 'active cult-button-alt-colors':''}`} onClick={setShopFilter}>Apparel{shopFilter === 'apparel' ? ' X':''}</li>
                    <li id="filter-neon" className={`quick-filter cult-button ${shopFilter === 'neon' ? 'active cult-button-alt-colors':''}`} onClick={setShopFilter}>Neon{shopFilter === 'neon' ? ' X':''}</li>
                </ul>
            </div>
        </FiltersWrap>
        )
    }
};

export default ShopFilters;