import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';

// UTILS
import { device } from "../../utils";


// ~~~~ STYLING
const CartItemWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0px;
  align-items: center;

  .item-remove {
    font-size: 20px;
    cursor: pointer;
    padding: 0px 6px 0px 9px;
    margin-right: 10px;
    box-shadow: none;
  }

  .item-image {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 15px;

    @media ${device.md} {
        width: 60px;
        height: 60px;
        margin-right: 15px;
    }
    
    img {
        width: 100vw;
        max-width: 100%;
    }
  }

  .item-name {
    width: calc(100% - 150px); // 55px image + 55px price + 40px for Remove button
    font-size: 15px;
    text-align: left;

    @media ${device.md} {
        width: calc(100% - 190px); // 75px image + 60px price + padding space + 40px remove button
        font-size: 20px;
    }   
  }

  .item-price {
    position: absolute;
    right: 0px;
    font-size: 15px;

    @media ${device.md} {
        font-size: 22px;
    } 
  }
`;


class CartItem extends React.Component {
  static contextType = LuxWeb3Context;
  constructor(props) {
    super(props);
    this.removeFromCart = this.removeFromCart.bind(this);
  }

  removeFromCart(){
        this.context.updateCart(this.props.product, 'remove')
  }

  render() {
    const { product } = this.props;
    const variantData = product.variants.find(variant => JSON.parse(variant.id) === JSON.parse(product.variant_id));
    let optionData = null;
    if (product.variants?.length > 1) {
        optionData = ` (${variantData.option1})`
    }


// ~~~~ COMPONENT BODY
    return (
      <CartItemWrap>
        <div className="item-remove cult-button" onClick={this.removeFromCart}>X</div>
        <div className='item-image'><img src={product.image_featured?.src} alt={`A ${product.title} in your cart`} /></div>
        <div className="item-name">{product.title}&nbsp;&nbsp;{optionData}</div>
        <div className="item-price">${variantData?.price?.split('.')[0]}</div>
      </CartItemWrap>
    )
  }
};

export default CartItem;
