import React from 'react'
import styled from "styled-components";

// CONTENT COMPONENTS
import { device } from "../../utils";
import ItemMedia from './ItemMedia';
import ItemDescription from './ItemDescription';
import ItemProperties from './ItemProperties';
import ItemOptions from './ItemOptions';
import ShopifyBuyButton from './ShopifyBuyButton';



// ~~~~ STYLING
const ShopGalleryItemWrap = styled.div`
  position: relative;
  overflow: visible;
  width: 100%;
  margin-bottom: 50px;
  display: inline-flex;
  flex-wrap: wrap;

  @media ${device.md} {
    width: calc( 50% - 10px );
  }

  @media ${device.xl} {
    width: calc( 32% - 10px );
  }


  // IDENTIFY NUMBER
  .item-number {
    position: absolute;
    top: -33px;
    left: -3px;
    padding: 0 20px;
    font-weight: bold;
    font-size: 20px;
    border: 3px solid #333;
    border-bottom: none;
  }


  // CONTENT PANE CONTROLS
  .product-content-pane {
    transition: opacity 0.5s ease-in-out;

    &.content-main { 
      opacity: 1; 
      pointer-events: all;
    }

    &.content-info { 
      position: absolute;
      top: 0;
      left: 0;
      height: calc( 100% - 80px );
      opacity: 0;
      pointer-events: none;
      overflow: auto;
    }
  }

  &.specs-view .product-content-pane {
    &.content-main { 
      opacity: 0; 
      pointer-events: none;
    }
    &.content-info { 
      opacity: 1; 
      pointer-events: all;
    }
  }



  // TITLE 
  .item-title {
    margin-bottom: 0;
    font-size: 24px;
    pointer-events: none;

    @media (min-width: 1300px) {
      font-size: 26px;
    }
  }

  // PRICE
  .item-price {
    margin-bottom: 15px;
    font-size: 26px;
    pointer-events: none;
  }

  // ITEM PROPERTIES
  .item-props {
    text-align: left;
    font-size: 17px;
    display: flex;
    flex-wrap: wrap;
  }

    .prop-item { 
      width: 100%;
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid #000;
      justify-content: space-between;
      padding: 6px 0;

      &:last-child {
        border-bottom-width: 0px;
      }
    }
  }


  // IMAGERY
  .item-media-wrap {
    padding-top: 15px;
  }

  // FORGE BUTTON
  .action-button-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    align-self: flex-end;

    .action-button {
      max-width: 47%;
    }
  }
  .action-button {
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 5px 10px;
    width: 100%;
    font-size: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media ${device.md} {
      font-size: 18px;
    }

    @media ${device.lg} {
      font-size: 18px;
    }

    @media (min-width:1400px) {
      font-size: 22px;
    }

    &:hover {
      background: #FFF;
      color: #000;
      text-shadow: none !important;
    }
  }
`;


class ShopGalleryItem extends React.Component {
  constructor(props) {
    super(props);
    const {product} = props;
    const selectionId = product?.variants?.length > 1 ? null : product.variant_id

    this.state = {
      showSpecs: false,
      selectedVariantId: selectionId
    }

    this.toggleSpecs = this.toggleSpecs.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { showSpecs } = this.state;
    const selection = this.props?.product?.id;
    const prevShowSpecs = prevState.showSpecs;

    // If specs shown, and previously was not shown, scroll to its top for user
    if ( !!showSpecs && !prevShowSpecs ) {
      this.resetContentScroll(selection);
    }
  }

  resetContentScroll(selection) {
    const scrollBox = document.getElementById( 'main-content' );
    const scrollTarget = document.getElementById( selection );
    if ( !!scrollBox && !!scrollBox.scrollTo && !!scrollTarget ) {
      scrollBox.scrollTo(0, 0);
      const targetLocation = scrollTarget.getBoundingClientRect();
      const parentLocation = scrollBox.getBoundingClientRect();
      const distanceToScroll = targetLocation.top - parentLocation.top - 30;
      // console.log( `selection top = ${targetLocation.top} \ntarget top    = ${parentLocation.height} \nscroll amount = ${distanceToScroll}` );
      scrollBox.scrollTo(0, distanceToScroll);
    } 
  }

  toggleSpecs() {
    this.setState({
      showSpecs: !this.state.showSpecs
    })
  }

  // OPTIONS : when an item has sizes, colors or something else
  selectOption(e) {
    const { id } = e.target;
    if ( id !== this.state.selectedVariantId ) {
      this.setState({ selectedVariantId: id });
    }
  }


  render() {
    const { product } = this.props;
    const { selectedVariantId, showSpecs } = this.state;


    // DETAIL + BUY BUTTONS
    let actionButton = (
      <div className="action-button-wrap">
        <a className="action-button cult-button" onClick={this.toggleSpecs}>{ showSpecs ? 'Back' : 'Details'}</a>
        <ShopifyBuyButton
          product={product}
          selectedVariantId={selectedVariantId}>
        </ShopifyBuyButton>
      </div>
    )


// ~~~~ COMPONENT BODY
    return (
      <ShopGalleryItemWrap id={product?.id} className={`cult-panel ${showSpecs ? 'specs-view':''}`}>

        {/* NUMBER */ 
          !!product.sku && (
            <div className="item-number cult-panel-colors">Case {product.sku}</div> )}


        {/* CONTENT 1 (of 2) - Main Image + Stats */}
        <div className="content-main product-content-pane">
        
          {/* MEDIA */}
          <ItemMedia className="item-media-wrap" product={product} />

          {/* TITLE */}
          <div className="item-title cult-link">{product.title}</div>
          <div className="item-price">${product.price}</div>

          {/* PROPERTIES */}
          <ItemProperties productTags={product.tags} mode={'preview'} />
        </div>


        {/* CONTENT 2 (of 2) - DETAIL DESCRIPTIONS */}
        <div id={`content-info-${product?.id}`} className="content-info product-content-pane">

            {/* DESCRIPTION */}
            <ItemDescription description={product.body_html} title={product.title} productTags={product.tags} />
        </div>


        {/* FORGE BUTTON */}
        <ItemOptions variants={product.variants} selectedVariantId={selectedVariantId} selectOption={this.selectOption} />
        {actionButton}
      </ShopGalleryItemWrap>
    )
  }
};

export default ShopGalleryItem;
