import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';
import { device } from "../../utils";

// ~~~~ STYLING
const ItemOptionsWrap = styled.div`
    display: block;
    width: 100%;

    &.hide {
        display: none;
    }


    .options-list {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .option-item {
            margin: 10px 15px;
            padding: 0px 16px 0px 20px;

            @media ${device.lg} {
                padding: 2px 14px 2px 20px;
            }

            &.sold-out {
                background:#999;
                pointer-events: none;
            }
        }
    } 
`;



class ItemOptions extends React.Component {
    static contextType = LuxWeb3Context;
    constructor(props) {
        super(props);
    }

    render() {
        const { 
            selectOption, 
            selectedVariantId, 
            variants, 
        } = this.props;


        const options = variants.map( variant => { return (
            <div 
                id={variant.id}
                key={variant.id}
                className={`option-item cult-button ${selectedVariantId === `${variant.id}` ? 'cult-button-alt-colors':''} ${variant.inventory_quantity > 0 ? '': 'sold-out'}`}
                onClick={selectOption}>
                    {variant.option1}
            </div>
        )})


        // ~~~~ COMPONENT BODY
        return (
        <ItemOptionsWrap className={`cult-panel-colors ${variants.length > 1 ? '' : 'hide' }`} >
            <ul className="options-list">
                {options}
            </ul>
        </ItemOptionsWrap>
        )
    }
};

export default ItemOptions;