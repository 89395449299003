import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';
import { device } from "../../utils";

// CONTENT COMPONENTS
import ContentLanding from "../../components/LuxContent/content/landing";
import ContentShop from "../../components/LuxContent/content/shop";
import ContentInfo from "../../components/LuxContent/content/info";
import ContentReliquary from "../../components/LuxContent/content/reliquary";
import ContentInitiate from "../../components/LuxContent/content/initiate";
import ContentLegal from '../../components/LuxContent/content/legal';
import ContentForge from "../../components/LuxContent/content/forge";
import ContentContact from './content/contactView';
import NftGallery from '../Web3Utils/NftGallery';
import ShopGallery from '../ShopCollection/ShopGallery';
import Cart from '../ShopCart/CartList';
import ForgePanel from './ForgePanel';

// ~~~~ STYLING
const ContentPanelWrap = styled.div`
  position: absolute;
  top: 82px;
  left: 50%;
  z-index: 1000; //Nav menu jumps to 2000 at open
  transform: translateX( -50% );
  max-width: 1600px;
  width: 94vw;
  height: calc( 100vh - 82px ); //82px = height with current header setup
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 5px 100px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  &.wallet-connected {
    top: 102px;
    height: calc( 100vh - 102px );

    @media only screen and (min-width:430px) {
      top: 132px;
      height: calc( 100vh - 132px );
    }
  }

  &.content-forge-wrap {
    max-width: 970px;
    display: block;
  }

  &.location-home,
  &.location-cart {
    #content-scroll-box { display: none; }
  }

  &.location-cart {
    height: auto;
  }

  .content-landing-wrap {
    max-height: 280px !important;
  }
`;  

const TextBox = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: left;

  // SCROLL CONTAINER CONTROLS
  &.content-inner-wrap {
    // max-height: 60vh;
    max-height: 100%;
    overflow: auto;
    padding: 5px;

    &.content-info-wrap {
      max-width: 1200px;
    }

    @media ${device.md} {
      padding: 5px 20px;
    }

    &.pad-bottom {
      padding-bottom: 60px;
    }
  }

  // HIDE CASES
  &.hide {
    display: none;
  }

  // HEADERS
  .content-header,
  .content-subheader {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
  }

  // BODY 
  .content-body {
    padding-left: 10px;
    font-size: 18px;
  }

  // SUB HEADER
  .content-subheader {
    margin-top: 25px;
  }

  &.shop-gallery-wrap {
    background: transparent;
    border: none; 
    box-shadow: none;
    overflow: visible;
    padding: 0;
  }

  // NFT GALLERY WRAP 
  &.nft-gallery-wrap {
    margin-top: 50px;
    background: transparent;
    border: none; 
    box-shadow: none;
    overflow: visible;
    padding: 0;
  }
`;


/* ~~~~ CONTENT FILLING
 * Controls header content on each page, below is a larger main content switch statement for the rest
 */
const ContentLibrary = {
  'cart': null,
  'contact': <ContentContact />,
  'forge': <ContentForge />,
  'home': null,
  'info': <ContentInfo />,
  'initiate': <ContentInitiate />,
  'legal': <ContentLegal />,
  'reliquary': <ContentReliquary />,
  // 'shop': <ContentShop />
  'shop': null
}


// ~~~~ COMPONENT WRAP
class ContentPanel extends React.Component {
  static contextType = LuxWeb3Context;

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { getAvailableNFTs } = this.context;
    getAvailableNFTs();
  }

  render() {
    let { 
       isWalletConnected, 
       location, 
       memberCount, 
       profileReady,
       shopifyProducts
    } = this.context;

    if ( location === 'reliquary' && !!profileReady && memberCount < 1 ) {
      location = 'initiate'
    }
    let DisplayedContent = ContentLibrary[location];
    

// ~~~~ COMPONENT BODY
    return (
      <ContentPanelWrap id="main-content" className={`${location === 'forge' ? 'content-forge-wrap' : ''} ${isWalletConnected ? 'wallet-connected' : ''} location-${location}`}>
        <TextBox id="content-scroll-box" className={`content-inner-wrap cult-panel ${location == 'info' ? 'pad-bottom content-info-wrap' : ''} ${ContentLibrary[location] === null ? 'hide': ''}`}>
          {DisplayedContent}
        </TextBox>


        {/* HOME (LANDING) ENTRY BUTTON */
          ( location === 'home' ) && (
            <ContentLanding />
          )
        }


        { /* SHOP / INITIATIE (Connected wallet, but no neon in wallet) */
          (( location === 'shop' || location === 'initiate' )) && (
            <TextBox className='shop-gallery-wrap cult-panel'>
              <ShopGallery shopifyProducts={shopifyProducts} />
            </TextBox>
          )
        }

        {/* CART VIEW */
          ( location === 'cart' ) && (
            <Cart />
          )
        }

        {/* MY NEON GALLERY (RELIQUARY) PANE */
          ( !!location && location === 'reliquary' ) && (
            <TextBox className='nft-gallery-wrap cult-panel'>
              <NftGallery />
            </TextBox>
        )}

        {/* FORGE - FORGE FORM */
          ( !!location && location === 'forge' ) && (
            <ForgePanel />
        )}
        
      </ContentPanelWrap>
    )
  }
};

export default ContentPanel;
